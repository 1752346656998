import { cn } from "lib/utils";
import { LoaderIcon } from "lucide-react";

export function Spinner({ className }: { className?: string }) {
  return (
    <LoaderIcon
      className={cn("size-6 animate-spin text-muted-foreground", className)}
    />
  );
}
